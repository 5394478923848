import React, { useEffect, useState } from "react";
import Icon from "../icons";
import Typography from "../utils/typography";
import * as styles from "./styles.module.scss";

const Sortby = ({ onChangeSorting, suppliers, className }) => {
  const [sortValue, setSortValue] = useState("Asc");
  const [sortby, setSortby] = useState(false);

  useEffect(() => {
    let sortedSuppliers = [...suppliers];
    switch (sortValue) {
      case "PriceDesc":
        sortedSuppliers = setSort("price", sortedSuppliers, true);
        break;
      case "PriceAsc":
        sortedSuppliers = setSort("price", sortedSuppliers);
        break;
      case "Desc":
        sortedSuppliers = setSort("name", sortedSuppliers);
        break;
      case "Asc":
        sortedSuppliers = setSort("name", sortedSuppliers, true);
        break;
      case "RateUp":
        sortedSuppliers = setSort("stars", sortedSuppliers, true);
        break;
      case "RateDown":
        sortedSuppliers = setSort("stars", sortedSuppliers);
        break;
      default:
        break;
    }
    onChangeSorting(sortedSuppliers);
  }, [sortValue]);

  const setSort = (property, suppliersToSort, reverse = false) => {
    suppliersToSort.sort((a, b) => {
      if (a[property] < b[property]) return reverse ? 1 : -1;
      if (a[property] > b[property]) return reverse ? -1 : 1;
      return 0;
    });
    return suppliersToSort;
  };

  const onChangeOption = (value) => {
    console.log("width", window.innerWidth);

    setSortValue(value);
    setSortby(false);
  };

  const handleSortbyChange = () => {
    setSortby(!sortby);
  };

  const mock = [
    {
      label: "Price high to low",
      value: "PriceDesc",
    },
    {
      label: "Price low to high",
      value: "PriceAsc",
    },
    {
      label: "A-Z",
      value: "Desc",
    },
    {
      label: "Z-A",
      value: "Asc",
    },
    {
      label: "Rating up",
      value: "RateUp",
    },
    {
      label: "Rating down",
      value: "RateDown",
    },
  ];
  return (
    <div className={`${styles.sortbyWrapper} ${className}`}>
      <Typography
        variant="h300-medium"
        component="button"
        onClick={handleSortbyChange}
        className={`${styles.sortbyButton} ${styles.sortbySelect}`}
      >
        <Icon
          className={`${styles.mobile} ${styles.sortbyIcon}`}
          icon={sortby ? "X" : "Sort"}
          size="S"
        />
        {mock.find((sort) => sort.value === sortValue)?.label}
        <Icon
          className={`${styles.desktop} ${styles.sortbyIcon}`}
          icon={sortby ? "ChevronUp" : "ChevronDown"}
          size="S"
        />
      </Typography>
      {sortby && (
        <div className={styles.options}>
          {mock.map((sort, index) => {
            return (
              <Typography
                variant="h400-medium"
                component="input"
                className={styles.option}
                inputLogic={{
                  type: "button",
                  value: sort.label,
                  onClick: () => onChangeOption(sort.value),
                }}
                key={`option_${index}`}
              />
            );
          })}
        </div>
      )}
      {/* 
            Este de aqui funciona pero tienes que seleccionar la opcion y luego seleccionar la opcion por defecto(select an option) para que se actualize la pagina
            <Select
                className={styles.selectFilter}
                options={mock.map((sort)=>{ return { value: sort.value, label: sort.label }})}
                value={sortValue}
                onChange={(e)=>setSortValue(e.target.value)}
            ></Select>
 */}{" "}
    </div>
  );
};

export default Sortby;
