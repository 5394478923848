import React from "react";
import { Button, Typography } from "../utils";
import Currency from "../currency";

import * as styles from "./styles.module.scss";

const ProductFirstCell = (props) => {
  const { name, description, price, currency, image } = props.product;

  return (
    <div className={styles.productFirstCell}>
      <picture className={styles.image}>
        <img src={image}></img>
      </picture>
      <div className={styles.wrapperInfo}>
        <div className={styles.mainInfo}>
          <Typography
            component="h3"
            variant="h400-medium"
            className={styles.title}
          >
            {name}
          </Typography>
          {description && (
            <Typography
              component="p"
              variant="h400-light"
              className={styles.description}
            >
              {description}
            </Typography>
          )}
        </div>
        <div className={styles.secondaryInfo}>
          <p className={styles.prices}>
            <Currency
              template="passholderProductTotal"
              currency={currency}
              price={price}
              displayDecimals={true}
            />
          </p>
          <Button
            className={styles.button}
            onClick={() => props.addProduct(props.product)}
          >
            <Typography wrap={false} component="span" variant="h400-medium">
              Book now
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductFirstCell;
