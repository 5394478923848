import React, { useState } from "react";

import Modal from "../modal";
import { Button, Typography } from "../utils";
import Accordion from "../accordion";

import * as styles from "./styles.module.scss";

const FiltersSortModal = ({
  toggleFilterSortModal,
  filterSupplier,
  filterType,
  setFilterType,
  supplierOptions,
  typeOptions,
  setFilterSupplier,
}) => {
  const [supplierModal, setSupplierModal] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  return (
    <Modal closeFn={() => toggleFilterSortModal(false)} size="small">
      <div className={styles.filters}>
        <div className={styles.filter}>
          <Button
            className={styles.buttonFilter}
            contentClassName={styles.filterContent}
            onClick={() => setSupplierModal(!supplierModal)}
          >
            <Typography variant="h400-heavy" component="span">
              Filter by supplier
            </Typography>
            <Typography variant="h400-medium" component="span">
              ({filterSupplier || "any"})
            </Typography>
          </Button>
          <Accordion open={supplierModal}>
            <div className={styles.rowWrapper}>
              {supplierOptions?.map((option) => {
                return (
                  <Button
                    className={styles.optionRow}
                    onClick={() => {
                      setFilterSupplier(option);
                      setSupplierModal(false);
                    }}
                  >
                    <Typography variant="h300-medium" component="span">
                      {option}
                    </Typography>
                  </Button>
                );
              })}
            </div>
          </Accordion>
        </div>
        <div className={styles.filter}>
          <Button
            className={styles.buttonFilter}
            contentClassName={styles.filterContent}
            onClick={() => setTypeModal(!typeModal)}
          >
            <Typography variant="h400-heavy" component="span">
              Filter by type
            </Typography>
            <Typography variant="h400-medium" component="span">
              ({filterType || "any"})
            </Typography>
          </Button>
          <Accordion open={typeModal}>
            <div className={styles.rowWrapper}>
              {typeOptions?.map((option) => {
                return (
                  <Button
                    className={styles.optionRow}
                    onClick={() => {
                      setFilterType(option);
                      setTypeModal(false);
                    }}
                  >
                    <Typography variant="h300-medium" component="span">
                      {option}
                    </Typography>
                  </Button>
                );
              })}
            </div>
          </Accordion>
        </div>
      </div>
    </Modal>
  );
};

export default FiltersSortModal;
