// extracted by mini-css-extract-plugin
export var productFirstCell = "styles-module--productFirstCell--2VVWf";
export var image = "styles-module--image--35iaU";
export var wrapperInfo = "styles-module--wrapperInfo--2xa3A";
export var mainInfo = "styles-module--mainInfo--1TaHl";
export var title = "styles-module--title--4bsOO";
export var description = "styles-module--description--2zCA0";
export var anchor = "styles-module--anchor--1XQlt";
export var secondaryInfo = "styles-module--secondaryInfo--2RuNY";
export var prices = "styles-module--prices--3MjCx";
export var button = "styles-module--button--htrx8";
export var link = "styles-module--link--1dlDD";
export var grid = "styles-module--grid--21PQr";