import React from "react";
import Modal from "../modal";
import { Button, Typography } from "../utils";
import Icon from "../icons";
import { LocaleAsString } from "../../localeEngine";
import * as styles from "./styles.module.scss";

const RemoveFromCartModal = ({
  deleteModal,
  productToDelete,
  toggleDeleteModal,
  deleteFromCart,
}) => {
  if (deleteModal)
    return (
      <Modal
        closeFn={() => toggleDeleteModal(false)}
        isAlert={true}
        size="small"
      >
        <div className={styles.deleteModal}>
          <div className={styles.wrapperIcon}>
            <Icon
              icon="Trash"
              size="Small"
              className={styles.icon}
              color={"#3595ba"}
            />
          </div>

          <Typography
            component="p"
            variant="p-heavy"
            className={styles.deleteTitle}
          >
            {productToDelete && productToDelete.name}
          </Typography>
          <Typography
            component="p"
            variant="p-medium"
            className={styles.deleteDescription}
          >
            <LocaleAsString id="cart.modal.delete_product" />
          </Typography>
        </div>
        <div className={styles.deleteCTA}>
          <div
            role="button"
            tabIndex={0}
            onClick={() => toggleDeleteModal(false)}
            onKeyPress={() => toggleDeleteModal(false)}
            className={styles.keep}
          >
            <Typography component={"span"} variant={"p-heavy"}>
              <LocaleAsString id="cart.modal.keep_it" />
            </Typography>
          </div>
          <Button onClick={() => deleteFromCart()} className={styles.remove}>
            <Typography component={"span"} variant={"p-heavy"}>
              <LocaleAsString id="cart.modal.delete" />
            </Typography>
          </Button>
        </div>
      </Modal>
    );
  return null;
};

export default RemoveFromCartModal;
