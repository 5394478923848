import React from "react";

import { Button, Typography } from "../../components/utils";

import * as styles from "./styles.module.scss";

const Filters = ({
  setSupplierModal,
  setTypeModal,
  filterSupplier,
  filterType,
}) => {
  return (
    <div className={styles.filters}>
      <Button
        className={styles.filter}
        contentClassName={styles.filterContent}
        onClick={() => setSupplierModal(true)}
      >
        <Typography variant="h400-heavy" component="span">
          Filter by supplier
        </Typography>
        <Typography variant="h400-medium" component="span">
          ({filterSupplier || "any"})
        </Typography>
      </Button>
      <Button
        className={styles.filter}
        contentClassName={styles.filterContent}
        onClick={() => setTypeModal(true)}
      >
        <Typography variant="h400-heavy" component="span">
          Filter by type
        </Typography>
        <Typography variant="h400-medium" component="span">
          ({filterType || "any"})
        </Typography>
      </Button>
    </div>
  );
};

export default Filters;
