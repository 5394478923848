import React, { useState } from "react";
import { navigate } from "gatsby-link";
import moment from "moment";
import { Button, Container, Typography } from "../../components/utils";
import ProductFirstCell from "../../components/productFirstCell";
import Sortby from "../../components/sortby";
import Layout from "../../components/layout";
import Currency from "../../components/currency";
import Modal from "../../components/modal";
import Icon from "../../components/icons";
import RemoveFromCartModal from "../../components/removeFromCartModal";
import MobileFiltersModal from "../../components/mobileFiltersModal";
import Filter from "../../components/icons/filter";
import Filters from "./filters";
import { LocaleAsString } from "../../localeEngine";
import mockedProducts from "./mockedProducts.json";
import * as styles from "./styles.module.scss";

const supplierOptions = [
  "Any",
  "Photography Tours",
  "Grocery Deliver",
  "History & Museums",
];

const typeOptions = [
  "Any",
  "Photography Tours",
  "Grocery Deliver",
  "History & Museums",
];

const ProductFirst = () => {
  const [suppliers, setSuppliers] = useState(mockedProducts);
  const [addedProducts, setAddedProducts] = useState([]);
  const [supplierModal, setSupplierModal] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [filterSupplier, setFilterSupplier] = useState(false);
  const [filterType, setFilterType] = useState(false);
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [filterSortModal, toggleFilterSortModal] = useState(false);

  const addProduct = (product) => {
    const products = [...addedProducts];
    products.push(product);
    setAddedProducts(products);
  };

  const getTotal = () => {
    let total = 0;
    addedProducts.forEach((p) => {
      total += p.price;
    });
    return total;
  };

  const openDeleteModal = (product) => {
    toggleDeleteModal(true);
    setProductToDelete(product);
  };

  const deleteFromCart = (product) => {
    const newAddedProducts = [...addedProducts];
    newAddedProducts.splice(addedProducts.indexOf(product), 1);
    setAddedProducts(newAddedProducts);
    toggleDeleteModal(false);
  };

  return (
    <Layout backgroundColor="#f4f4f9" hideMobileFooter={true}>
      <Container className={styles.productFirstContainer} maxWidth="lg">
        <div className={styles.mainContent}>
          {supplierModal && (
            <Modal size={"small"} closeFn={() => setSupplierModal(false)}>
              <Typography
                component="h1"
                variant="h400-medium"
                className={styles.title}
              >
                Filters
              </Typography>
              <div className={styles.rowWrapper}>
                {supplierOptions.map((option) => {
                  return (
                    <Button
                      className={styles.optionRow}
                      onClick={() => {
                        setFilterSupplier(option);
                        setSupplierModal(false);
                      }}
                    >
                      <Typography variant="h300-medium" component="span">
                        {option}
                      </Typography>
                    </Button>
                  );
                })}
              </div>
            </Modal>
          )}
          {typeModal && (
            <Modal size={"small"} closeFn={() => setTypeModal(false)}>
              <Typography
                component="h1"
                variant="h400-medium"
                className={styles.title}
              >
                Filters
              </Typography>
              <div className={styles.rowWrapper}>
                {typeOptions.map((option) => {
                  return (
                    <Button
                      className={styles.optionRow}
                      onClick={() => {
                        setFilterType(option);
                        setTypeModal(false);
                      }}
                    >
                      <Typography variant="h300-medium" component="span">
                        {option}
                      </Typography>
                    </Button>
                  );
                })}
              </div>
            </Modal>
          )}
          {filterSortModal && (
            <MobileFiltersModal
              suppliers={suppliers}
              setSuppliers={setSuppliers}
              toggleFilterSortModal={toggleFilterSortModal}
              filterSupplier={filterSupplier}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterSupplier={setFilterSupplier}
              supplierOptions={supplierOptions}
              typeOptions={typeOptions}
            />
          )}
          <Filters
            setTypeModal={setTypeModal}
            setSupplierModal={setSupplierModal}
            filterType={filterType}
            filterSupplier={filterSupplier}
          />
          <div className={styles.resultSummary}>
            <Typography variant="h400-medium" component="span">
              Found 4 option results.
            </Typography>
            <Sortby
              className={styles.desktopSort}
              suppliers={suppliers}
              onChangeSorting={setSuppliers}
            />
          </div>
          <div className={styles.mobileFilterSort}>
            <Button
              onClick={() => toggleFilterSortModal(true)}
              className={styles.filterButton}
            >
              <Filter color="#242426" />
            </Button>
            <Sortby
              className={styles.mobileSort}
              suppliers={suppliers}
              onChangeSorting={setSuppliers}
            />
          </div>

          <div className={styles.products}>
            {suppliers.map((prod, index) => {
              return (
                <ProductFirstCell
                  product={prod}
                  index={index}
                  addProduct={addProduct}
                />
              );
            })}
          </div>
        </div>
        <aside className={styles.sidebar}>
          <div className={styles.summaryCart}>
            {addedProducts.length > 0 && (
              <>
                <div className={styles.totalCart}>
                  <Typography component="span" variant="h600-heavy">
                    <LocaleAsString id="lift_tickets.form.products.total" />
                  </Typography>
                  <Currency
                    displayDecimals={true}
                    currency={"USD"}
                    template="passholderTotal"
                    price={getTotal()}
                  />
                </div>
                <div className={styles.cartProducts}>
                  {addedProducts.map((prod, idx) => {
                    const {
                      name,
                      price,
                      currency,
                      supplier,
                      quantity = 1,
                    } = prod;
                    return (
                      <div className={styles.cartProduct}>
                        <div className={styles.cartProductMainInfo}>
                          <Typography
                            className={styles.supplierName}
                            variant="h300-medium"
                            component="span"
                          >
                            {supplier}
                          </Typography>
                          <Typography variant="h500-medium" component="span">
                            {name}
                          </Typography>
                          <Typography variant="h300-medium" component="span">
                            {moment.utc().format("ddd, DD MMM YYYY")}
                          </Typography>
                        </div>
                        <div className={styles.quantityPriceRow}>
                          <Icon
                            icon="Trash"
                            size="Small"
                            onClick={() => openDeleteModal(prod)}
                            color={"#525255"}
                          />
                          <div className={styles.quantityPrice}>
                            <Typography variant="h300-medium" component="span">
                              x {quantity}
                            </Typography>
                            <Currency
                              price={price}
                              currency={currency}
                              template={"checkoutBundle"}
                              displayDecimals={false}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.productFormBottom}>
                  <div className={styles.bottomRow}>
                    <Typography component="span" variant="h600-heavy">
                      <LocaleAsString id="lift_tickets.form.products.total" />
                    </Typography>
                    <Currency
                      displayDecimals={true}
                      currency={"USD"}
                      template="passholderTotal"
                      price={getTotal()}
                    />
                  </div>
                  <div className={styles.bottomRow}>
                    <Typography component="span" variant="h300-medium">
                      <LocaleAsString
                        id="lift_tickets.form.products.vat"
                        replace={12}
                      />
                    </Typography>
                    <Currency
                      displayDecimals={true}
                      currency={"USD"}
                      template="taxesCheckout"
                      price={getTotal() * (12 / 100)}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={styles.bottomCart}>
              <Button
                onClick={() => navigate("/cart")}
                className={styles.goToCart}
              >
                <Typography component="span" variant="h400-heavy">
                  Go to cart
                </Typography>
              </Button>
              {addedProducts.length ? (
                <div className={styles.itinerary}>
                  <Typography component="span" variant="h400-medium">
                    Itinerary # 123456
                  </Typography>
                </div>
              ) : null}
            </div>
            <RemoveFromCartModal
              deleteModal={deleteModal}
              productToDelete={productToDelete}
              toggleDeleteModal={toggleDeleteModal}
              deleteFromCart={deleteFromCart}
            />
          </div>
        </aside>
      </Container>
    </Layout>
  );
};

export default ProductFirst;
